/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Widex Unique line is ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/widex/",
    className: "c-md-a"
  }, "Widex’s"), " newest release, available as CIC, ITE, BTE, and RIC devices. New features include improved analog-to-digital (A/D) converters to deliver better sounds, a sound classifier for intelligent listening, and the Wind Noise Attenuation system that dramatically helps you listen in windy conditions."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "improved-ad-converters--sound-processing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#improved-ad-converters--sound-processing",
    "aria-label": "improved ad converters  sound processing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Improved A/D converters & sound processing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Widex Unique ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " contain four A/D converters that capture a wide range of sounds and deliver them without distortion. The extreme highs and the soft lows are captured, so that users can benefit from a 108 dB dynamic range and hear it all."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Unique line utilizes Sound Class Technology, a processing system, to adapt to changing sound environments without compromising your quality of hearing. Unique hearing aids are programmed with a universal listening program that classifies sound environments into nine distinct categories. Thus, sound processing is optimized for each type of listening environment, according to their distinct characteristics. This maximizes the degree to which you can understand speech in different environments."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "outdoor-listening-optimization",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#outdoor-listening-optimization",
    "aria-label": "outdoor listening optimization permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Outdoor listening optimization"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One of the biggest highlights of the Unique line is the attention to outdoor listening environments. The Soft Level Noise Reduction feature tones down unwanted background noise to remove distractions. Wind can also be a huge barrier to good hearing in the outdoors. The Unique’s Wind Noise Attenuation system addresses this problem by providing an 8.4 dB SNR improvement in wind noise. Whether you are out mountain biking or sailing on a windy day, the SoundUnique hearing aids will take care of helping you hear despite the wind blowing into your ears from all directions."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "wireless-connectivity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#wireless-connectivity",
    "aria-label": "wireless connectivity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Wireless Connectivity"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Unique hearing aids offer the feature of wireless communication through the use of the COM-DEX, a pendant you can wear around your neck to pair the hearing aids to your phone. Wireless connectivity will enable you to stream audio directly to the hearing aids, and change settings and volumes straight from your smartphone. This removes the need for having an extra remote control device to adjust volumes or settings, since it can be done with your smartphone instead. With the possibility of wireless communication, the Unique line can also connect to your TV and computer to stream content directly to the hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We would be happy to help you find a hearing aid provider in your area and arrange an appointment for you. Our experts advise you on hearing aids and hearing difficulties and take care of everything. Please contact us for further information and take advantage of our free and non-binding consultation services."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Widex Unique now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
